import type { Money } from '@/types/shopify'

export const formatDateTime = (date?: Date | string, options: Intl.DateTimeFormatOptions = {}) => {
  if (!date) {
    return
  }
  date = new Date(date)
  options = {
    dateStyle: 'medium',
    ...options
  }
  return new Intl.DateTimeFormat('default', options).format(date).split('/').join('-')
}

interface FormatCurrencyOptions {
  currency?: string
  stripZeros?: boolean
  multiplier?: number
}

export const formatCurrency = (amount: number | string, { stripZeros, currency, multiplier }: FormatCurrencyOptions = {}) => {
  if (typeof amount === 'string') {
    amount = parseFloat(amount)
  }
  const formatted = new Intl.NumberFormat('us-EN', {
    style: 'currency',
    currency: currency || 'USD',
    maximumFractionDigits: 2
  }).format(amount * (multiplier || 1))
  return stripZeros !== false ? formatted.replace('.00', '') : formatted
}

export const formatMoney = ({ amount, currencyCode }: Money, options: FormatCurrencyOptions = {}) => formatCurrency(amount, { currency: currencyCode, ...options })

export const formatPhone = (phone: string) => phone.replace(/(\+\d+)?(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4').trim()

export const cleanTitle = (title: string) => title.replace(/(subscription|auto\srenew|instant\sprotein\scoffee)/ig, '').trim()

// TODO: replace with real inflections when the time comes
export const pluralize = (string: string, quantity: number | undefined) => quantity === 1 ? string : `${string}s`
